import * as React from "react";
import { Button, Box, Flex, Text } from "@yamsafer/styleguide";
import { IconContext } from "react-icons";

export type Option = {
  props?: any;
  as: React.ReactNode;
  label: React.ReactNode;
  icon: React.ReactNode;
};

export type Options = Option[];

interface MenuProps {
  options: Options;
}

export const MenuItem = ({ as, label, icon, props }: Option) => (
  <Button m={0} my={1} as={as} width="100%" variant="transparent" {...props}>
    <Flex justifyContent="space-between" alignItems="center">
      <Text color="primary" fontSize={"1.166666667rem"}>
        {label}
      </Text>
      <Text color="primary">{icon}</Text>
    </Flex>
  </Button>
);
const Menu = ({ options }: MenuProps) => (
  <IconContext.Provider value={{ size: "18px" }}>
    {options.map((option, index) => (
      <MenuItem key={index} {...option} />
    ))}
  </IconContext.Provider>
);

export default Menu;
