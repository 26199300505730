import useSWR from "swr";
import fetch from "isomorphic-unfetch";
import { parseCookies } from "nookies";

const useCustomer = (api = "profile", options = {}) => {
  const { authentication } = parseCookies();
  const isClientSide = typeof window !== "undefined";
  const cacheKey = `__${api}`;
  const cached = isClientSide && window[cacheKey];

  // if there is no cookie, there is no hope for this user :D
  if (!authentication) {
    return {
      data: null,
      isLoading: false
    };
  }

  // is in cache?
  if (!options.force && isClientSide && cached) {
    return {
      data: cached,
      isLoading: false
    };
  }

  const { data, error, isValidating } = useSWR(
    authentication ? process.env.backendApi + `api/v3/customers/${api}` : null,
    url =>
      fetch(url, {
        headers: { Authorization: `Bearer ${authentication}` }
      }).then(res => res.json()),
    {
      revalidateOnFocus: false
    }
  );

  // cache it
  if (isClientSide && data) {
    window[cacheKey] = data;
  }

  return {
    data,
    isLoading: isValidating
  };
};

export const useWallet = (api = "wallets", options = {}) =>
  useCustomer(api, options);

export default useCustomer;
